var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.task)?[_c('h1',[_vm._v(_vm._s(_vm.task.title))]),_c('div',{staticClass:"flex-start pb-3"},[_c('span',{staticClass:"subtitle-1 grey--text text--darken-1",domProps:{"textContent":_vm._s(_vm.authorStr())}}),_c('v-dialog',{attrs:{"eager":"","fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.imAuthor)?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":""}},'v-btn',attrs,false),on),[_vm._v("Показать решения")]):_vm._e()]}}],null,false,1739207447),model:{value:(_vm.showAttempts),callback:function ($$v) {_vm.showAttempts=$$v},expression:"showAttempts"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showAttempts=false}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.updateAttempts}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-update")])],1)]}}],null,false,1524936158)},[_c('span',[_vm._v("Обновить попытки")])]),_c('v-text-field',{staticClass:"mx-4 rounded-lg",staticStyle:{"max-width":"400px"},attrs:{"hide-details":"","single-line":"","solo":"","flat":!_vm.searchFocused,"background-color":_vm.searchFocused
              ? '' : 'grey lighten-3',"label":"Поиск","prepend-inner-icon":"mdi-magnify","clearable":true,"clear-icon":"mdi-close"},on:{"focus":function($event){_vm.searchFocused = true},"blur":function($event){_vm.searchFocused = false}},model:{value:(_vm.attemptsSearch),callback:function ($$v) {_vm.attemptsSearch=$$v},expression:"attemptsSearch"}})],1),_c('attempts-table',{ref:"attemptsTable",attrs:{"task":_vm.task,"search":_vm.attemptsSearch}})],1)],1)],1),_c('v-stepper',{staticClass:"elevation-0 ma-0 rounded-0",attrs:{"non-linear":""},model:{value:(_vm.currentQuestion),callback:function ($$v) {_vm.currentQuestion=$$v},expression:"currentQuestion"}},[_c('v-stepper-header',[_c('v-slide-group',{attrs:{"show-arrows":"","center-active":"","value":_vm.currentQuestion-1}},_vm._l((_vm.task.questions),function(question,i){return _c('v-slide-item',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var active = ref.active;
              var toggle = ref.toggle;
return [_c('v-stepper-step',{attrs:{"step":i+1,"editable":"","complete":_vm.statuses[i] == _vm.StatusEnum.correct,"edit-icon":"mdi-check","color":_vm.statuses[i] == _vm.StatusEnum.correct ? 'success' : 'blue darken-2',"rules":[function () { return _vm.statuses[i] != _vm.StatusEnum.wrong; }],"error-icon":"mdi-close"},on:{"click":function($event){toggle(); _vm.questionSwitched = true}}},[_vm._v(" "+_vm._s(question.title)+" ")])]}}],null,true)})}),1)],1),_c('v-stepper-items',[_vm._l((_vm.task.questions),function(question,i){return _c('v-stepper-content',{key:i,attrs:{"step":i+1}},[_c('div',{staticClass:"mx-3"},[(question.title)?_c('h2',{staticClass:"mb-3"},[_vm._v(_vm._s(question.title))]):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(question.text)}}),(['textQuestion', 'choiceQuestion'].includes(question.type))?_c('text-question',{key:i,ref:"question",refInFor:true,staticClass:"pa-0",attrs:{"question":question,"check_on_submit":_vm.task.check_on_submit},on:{"statusChange":function (val) { return _vm.onStatusChange(i, val); }}}):_vm._e(),(question.type == 'orderQuestion')?_c('order-question',{key:i,ref:"question",refInFor:true,staticClass:"pa-0",attrs:{"question":question,"check_on_submit":_vm.task.check_on_submit},on:{"statusChange":function (val) { return _vm.onStatusChange(i, val); }}}):_vm._e()],1)])}),_c('v-stepper-content',{attrs:{"step":_vm.task.questions.length+1}},[(_vm.task.check_on_submit)?[_c('h2',[_vm._v("Завершить")]),_c('p',[_vm._v("Вы ответили на все вопросы")]),_c('v-btn',{attrs:{"depressed":"","color":"blue darken-2","dark":""},on:{"click":_vm.submitAll}},[_vm._v("Проверить")])]:[_c('h2',[_vm._v("Готово!")]),_c('p',[_vm._v("Вы выполнили задание")])]],2)],2)],1)]:_c('v-skeleton-loader',{attrs:{"type":"article"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }