var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-form',{ref:"form"},[_c('improved-text-field',{attrs:{"label":"Заголовок задания","max-length":64,"required":""},model:{value:(_vm.taskData.title),callback:function ($$v) {_vm.$set(_vm.taskData, "title", $$v)},expression:"taskData.title"}}),_c('div',{staticClass:"mb-5"},[_c('v-stepper',{staticClass:"elevation-0 ma-0 rounded-0",attrs:{"non-linear":""},model:{value:(_vm.currentQuestion),callback:function ($$v) {_vm.currentQuestion=$$v},expression:"currentQuestion"}},[_c('v-stepper-header',{ref:"stepperHeader",staticClass:"flex-space-between"},[_c('v-slide-group',{ref:"slideGroup",attrs:{"show-arrows":"","center-active":"","value":_vm.currentQuestion-1}},_vm._l((_vm.questions),function(question,i){return _c('v-slide-item',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-stepper-step',{attrs:{"step":i+1,"editable":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(question.title.trim() || 'Новый вопрос')+" ")])]}}],null,true)})}),1),_c('v-card',{ref:"buttons",staticClass:"rounded-pill pa-1",attrs:{"outlined":""}},[_c('div',{staticClass:"flex-space-between"},[_vm._l((_vm.createButtons),function(button,i){return _c('v-tooltip',{key:i,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:i < _vm.createButtons.length-1
                    || _vm.questions.length > 0 || true ? 'mr-1' : '',attrs:{"fab":"","small":"","icon":""},on:{"click":function($event){return _vm.createQuestion(button.type)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(button.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(button.label))])])}),_c('v-fab-transition',[(_vm.questions.length > 0 || true)?_c('v-btn',{attrs:{"fab":"","dark":"","small":"","depressed":"","color":"red"},on:{"click":_vm.removeQuestion}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],2)])],1),_c('v-tabs-items',{staticClass:"px-10",attrs:{"value":_vm.currentQuestion-1}},_vm._l((_vm.questions),function(question,i){return _c('v-tab-item',{key:i},[_c('create-question-wrapper',{ref:"createQuestion",refInFor:true,attrs:{"question":question}})],1)}),1)],1),_c('v-switch',{attrs:{"label":"Проверять при отправлении","hint":"Показывать правильность решения каждого вопроса только после\n        отправления всего задания","persistent-hint":""},model:{value:(_vm.taskData.check_on_submit),callback:function ($$v) {_vm.$set(_vm.taskData, "check_on_submit", $$v)},expression:"taskData.check_on_submit"}})],1),_c('v-alert',{attrs:{"value":_vm.questions.length == 0 && _vm.submitted,"type":"error"}},[_vm._v(" Вы не создали ни одного вопроса ")]),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"blue darken-2"},on:{"click":_vm.saveTask}},[_vm._v("Сохранить")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }